<template>
  <b-card no-body class="mb-0 mt-1 p-2">
    <b-tabs>
      <b-tab title="Lịch thanh toán" active><payroll-detail-repayment-schedule /> </b-tab>
      <b-tab title="Lịch sử thanh toán"
        ><payroll-detail-history-payment
          v-if="
            $can(
              permissionCode.PAYROLL_REPAYMENT_HISTORY.action,
              permissionCode.PAYROLL_REPAYMENT_HISTORY.resource,
            )
          "
      /></b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import permissionCode from '@/constants/permissionCode';
import PayrollDetailHistoryPayment from './PayrollDetailHistoryPayment.vue';
import PayrollDetailRepaymentSchedule from './PayrollDetailRepaymentSchedule.vue';

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    PayrollDetailHistoryPayment,
    PayrollDetailRepaymentSchedule,
  },
  data() {
    return {
      permissionCode,
    };
  },
};
</script>
