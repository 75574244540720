import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useHistoryDetailListPayment() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);
  const router = useRouter();
  const { id } = router.route.value.params;
  // Table Handlers
  const tableColumns = [
    {
      key: 'createdAt',
      sortable: false,
      label: 'Ngày giao dịch',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'period',
      sortable: false,
      label: 'Số Kỳ',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'transactionCode',
      sortable: false,
      label: 'Mã giao dịch',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'amount',
      sortable: false,
      label: 'Đã thanh toán',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'status',
      sortable: false,
      label: 'Trạng thái',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'employeeName',
      sortable: false,
      label: 'Nhân viên thực hiện',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
    {
      key: 'documents',
      sortable: false,
      label: 'Đính kèm',
      tdClass: 'p-1',
      thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:12px',
    },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // const searchQuery = ref('');
  // const sortBy = ref('id');
  // const isSortDirDesc = ref(false);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, statusFilter, typeFilter, startDate, endDate], () => {
    refetchData();
  });

  const fetchPayrollPaymentList = (ctx, callback) => {
    store
      .dispatch('app-payroll/fetchPayrollDetailRepaymentHistory', {
        limit: perPage.value,
        page: currentPage.value,
        status: statusFilter.value,
        type: typeFilter.value,
        startDate: startDate.value,
        endDate: endDate.value,
        id,
      })
      .then((response) => {
        const { data, meta } = response.data;
        callback(data);
        totalContract.value = meta.total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Có lỗi xảy ra',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 0 || value === 1 || value === 2 || value === 3) return 'warning';
    if (value === 5) return 'danger';
    if (value === 4) return 'primary';
    return '';
  };
  const resolveStatus = (value) => {
    if (value === 0 || value === 1 || value === 2 || value === 3) return 'Đang xử lý';
    if (value === 4) return 'Thành công';
    if (value === 5) return 'Thất bại';
    return '';
  };
  const statusOptions = [
    { label: 'Mới', value: 0 },
    { label: 'Đang xử lý', value: 1 },
    { label: 'Thu nợ', value: 3 },
    { label: 'Trễ', value: 4 },
    { label: 'Nợ xấu', value: 5 },
    { label: 'Từ chối', value: 6 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchPayrollPaymentList,
    tableColumns,
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    statusFilter,
    typeFilter,
    resolveUserStatusVariant,
    resolveStatus,
    refetchData,
  };
}
