<template>
  <div>
    <!-- Table Container Card -->
    <h4 class="text-center pb-1">Lịch sử thanh toán</h4>
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="fetchPayrollPaymentList"
      responsive
      :fields="tableColumns"
      show-empty
      empty-text="Không tìm thấy kết quả"
    >
      <template #cell(createdAt)="data">
        <format-time :time="data.item.createdAt" />
      </template>
      <!-- Column: Amount -->
      <template #cell(amount)="data">
        <span>{{ numberWithCommas(data.item.amount) }}</span>
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ resolveStatus(data.item.status) }}
        </b-badge>
      </template>
      <template #cell(documents)="data">
        <ul class="p-0 m-0" style="list-style-type: none">
          <li v-for="item in data.item.documents" :key="item" style="padding: 5px">
            <a v-if="item" :href="item" target="_blank" download><ins>UNC</ins></a>
          </li>
        </ul>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalContract"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BTab,
  BTabs,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import usePayrollDetailHistoryPayment from './usePayrollDetailHistoryPayment';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTab,
    BTabs,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    FormatTime,
    vSelect,
  },
  setup(props, { root }) {
    // UnRegister on leave
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchPayrollPaymentList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      resolveStatus,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
    } = usePayrollDetailHistoryPayment();
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchPayrollPaymentList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,
      resolveStatus,
      numberWithCommas,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
