<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-1 p-2">
      <h4 class="text-center">Danh sách chi lương</h4>
      <b-row class="mb-1 justify-content-between align-items-end">
        <b-row class="ml-0">
          <b-col cols="6" class="mb-md-0 mb-2">
            <label>Họ và tên</label>
            <b-form-input class="d-inline-block mr-1" :value="fullName" @input="debounceSearch" />
          </b-col>
          <b-col cols="6" class="mb-md-0 mb-2">
            <label>Số tài khoản</label>
            <b-form-input
              class="d-inline-block mr-1"
              :value="accountNumber"
              @input="debounceSearchAccountNumber"
            />
          </b-col>
        </b-row>
        <b-col cols="3" class="mb-md-0 mb-2 d-flex justify-content-end">
          <b-button
            v-if="payrollDetailInfor.documentUrl"
            variant="outline-primary"
            @click="handleExportStatementPayroll"
            ><feather-icon icon="DownloadCloudIcon" class="mr-1" size="17" />Tải file
            excel</b-button
          >
        </b-col>
      </b-row>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPayrollContractList"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Không tìm thấy kết quả"
      >
        <!-- Column: customer -->
        <template #cell(fullName)="data">
          <span>{{ data.item.customer.fullName }}</span>
        </template>
        <template #cell(nicNumber)="data">
          <span>{{ data.item.customer.nicNumber }}</span>
        </template>
        <template #cell(accountNumber)="data">
          <span>{{ data.item.bankAccount.accountNumber }}</span>
        </template>
        <template #cell(bank)="data">
          <span>{{ data.item.bankAccount.bankName }}</span>
        </template>
        <template #cell(transactionNote)="data">
          <div style="width: 300px" class="text-truncate">{{ data.item.transactionNote }}</div>
        </template>
        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <span>{{ numberWithCommas(data.item.amount) }}</span>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(documentUrl)="data">
          <b-spinner v-if="data.item.loading" variant="primary" />
          <a
            v-if="
              !data.item.loading &&
              (data.item.status === 2 ||
                data.item.status === 3 ||
                data.item.status === 4 ||
                data.item.status === 5 ||
                data.item.status === 10)
            "
            target="_blank"
            class="text-info font-weight-bold"
            @click="handleExportStatementContract(data.item)"
            ><ins>Xem</ins></a
          >
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { exportStatementContract } from '@/apis/apiPayroll';
import usePayrollDetailSalaryList from './usePayrollDetailSalaryList';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  setup(props, { root }) {
    // UnRegister on leave
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchPayrollContractList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      resolveStatus,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
      fullName,
      accountNumber,
    } = usePayrollDetailSalaryList();
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchPayrollContractList,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,
      resolveStatus,
      numberWithCommas,
      fullName,
      accountNumber,
    };
  },
  data() {
    return {
      debounce: null,
    };
  },
  computed: {
    ...mapGetters({
      payrollDetailInfor: 'app-payroll/getPayrollDetailInfor',
    }),
  },
  methods: {
    debounceSearch(val) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.fullName = val;
      }, 1000);
    },
    debounceSearchAccountNumber(val) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.accountNumber = val;
      }, 1000);
    },
    handleExportStatementContract(item) {
      item.loading = true;
      exportStatementContract(item.transactionId)
        .then((res) => {
          const url = window.URL.createObjectURL(res.data);

          const link = document.createElement('a');
          link.href = url;
          // link.setAttribute('', `transaction_statement_${item.code}.pdf`);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          item.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Có lỗi xảy ra',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response?.data.message,
            },
          });
        });
    },
    handleExportStatementPayroll() {
      window.open(this.payrollDetailInfor.documentUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
