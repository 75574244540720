<template>
  <div>
    <!-- Table Container Card -->
    <h4 class="text-center pb-1">Lịch thanh toán</h4>
    <b-table
      class="position-relative"
      :items="payrollDetailInfor.repaymentSchedules"
      responsive
      :fields="tableColumns"
      show-empty
      empty-text="Không tìm thấy kết quả"
      style="overflow: unset"
    >
      <template #cell(dueDate)="data">
        <format-time v-if="!isEdit" :time="data.item.dueDate" format-type="DD/MM/YYYY" />
        <b-form-datepicker
          v-else
          :min="minDay"
          :value="data.item.dueDate"
          :disabled="data.item.totalAmount === data.item.paidAmount"
          :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
          locale="en-GB"
          placeholder=""
          style="z-index: 10000"
          @input="(val) => handleChange(data, val)"
        />
      </template>
      <template #cell(paymentDate)="data">
        <format-time :time="data.item.paymentDate" format-type="DD/MM/YYYY" />
      </template>
      <!-- Column: Amount -->
      <template #cell(originalAmount)="data">
        <span>{{ numberWithCommas(data.item.originalAmount) }}</span>
      </template>
      <template #cell(penaltyAmount)="data">
        <span v-if="!isEdit">{{ numberWithCommas(data.item.penaltyAmount) }}</span>
        <b-form-input
          v-else
          :disabled="data.item.totalAmount === data.item.paidAmount"
          :value="numberWithCommas(data.item.penaltyAmount)"
          :formatter="(val) => numberWithCommas(numberWithoutCommas(val))"
          @input="(val) => handleChange(data, val)"
        />
      </template>
      <template #cell(feeAmount)="data">
        <span>{{ numberWithCommas(data.item.feeAmount) }}</span>
      </template>
      <template #cell(totalAmount)="data">
        <span
          ><b style="color: #000">{{ numberWithCommas(data.item.totalAmount) }}</b></span
        >
      </template>
      <template #cell(paidOriginalAmount)="data">
        <span>{{ numberWithCommas(data.item.paidOriginalAmount) }}</span>
      </template>
      <template #cell(paidFeeAmount)="data">
        <span>{{ numberWithCommas(data.item.paidFeeAmount) }}</span>
      </template>
      <template #cell(paidPenaltyAmount)="data">
        <span>{{ numberWithCommas(data.item.paidPenaltyAmount) }}</span>
      </template>
      <template #cell(paidAmount)="data">
        <span>{{ numberWithCommas(data.item.paidAmount) }}</span>
      </template>
      <template #cell(remainAmount)="data">
        <span
          ><b style="color: #000">{{ numberWithCommas(data.item.remainAmount) }}</b></span
        >
      </template>
    </b-table>
    <b-button
      v-if="
        (payrollDetailInfor.status === 6 || payrollDetailInfor.status === 8) &&
        !isEdit &&
        $can(
          permissionCode.PAYROLL_PAYMENT_SCHEDULE_UPDATE.action,
          permissionCode.PAYROLL_PAYMENT_SCHEDULE_UPDATE.resource,
        )
      "
      variant="primary"
      @click="handleClick"
      >Thêm phí</b-button
    >
    <div
      v-if="
        isEdit &&
        $can(
          permissionCode.PAYROLL_PAYMENT_SCHEDULE_UPDATE.action,
          permissionCode.PAYROLL_PAYMENT_SCHEDULE_UPDATE.resource,
        )
      "
      class="d-flex"
    >
      <b-button class="mr-1" variant="outline-primary" @click="handleCancel">Huỷ</b-button>
      <b-button variant="primary" @click="handleConfirmUpdate">Lưu</b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import store from '@/store';
import { numberWithCommas, numberWithoutCommas, useRouter } from '@core/utils/utils';
import { updateRepaymentSchedule } from '@/apis/apiPayroll';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import permissionCode from '@/constants/permissionCode';
// import useHistoryDetailRepaymentSchedule from './useHistoryDetailRepaymentSchedule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    FormatTime,
    vSelect,
  },
  setup(props, { root }) {
    // UnRegister on leave
    // const {
    //   tableColumns,
    //   // filter
    // } = useHistoryDetailRepaymentSchedule();
    const router = useRouter();
    const { id } = router.route.value.params;
    return {
      id,
      numberWithCommas,
      numberWithoutCommas,
      tableColumns: [
        {
          key: 'period',
          sortable: false,
          label: 'Kỳ',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'dueDate',
          sortable: false,
          label: 'Ngày đến hạn',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'originalAmount',
          sortable: false,
          label: 'Số tiền ứng',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'feeAmount',
          sortable: false,
          label: 'Phí dịch vụ',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'penaltyAmount',
          sortable: false,
          label: 'Phí phạt',
          tdClass: 'p-1',
          thStyle:
            'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px;width:150px',
        },
        {
          key: 'totalAmount',
          sortable: false,
          label: 'Tổng thanh toán',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'paymentDate',
          sortable: false,
          label: 'Ngày thanh toán',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'paidOriginalAmount',
          sortable: false,
          label: 'Tiền gốc',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'paidFeeAmount',
          sortable: false,
          label: 'Tiền phí',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'paidPenaltyAmount',
          sortable: false,
          label: 'Phí phạt',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'paidAmount',
          sortable: false,
          label: 'Đã thu',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
        {
          key: 'remainAmount',
          sortable: false,
          label: 'Còn lại',
          tdClass: 'p-1',
          thStyle: 'fontSize:12px;fontWeight:500;textTransform: unset;letterSpacing:0;padding:10px',
        },
      ],
      permissionCode,
    };
  },
  data() {
    return {
      minDay: new Date(new Date().setDate(new Date().getDate())),
      isEdit: false,
      repaymentSchedule: [],
      fee: '',
    };
  },
  computed: {
    ...mapGetters({
      payrollDetailInfor: 'app-payroll/getPayrollDetailInfor',
    }),
  },
  methods: {
    handleClick() {
      this.isEdit = true;
    },
    handleCancel() {
      this.isEdit = false;
      this.repaymentSchedule = [];
    },
    handleChange(data, val) {
      if (data.field.key === 'penaltyAmount') {
        val = parseInt(numberWithoutCommas(val), 10);
      }
      // kiem tra lich thu da ton tai chua
      const idx = this.repaymentSchedule.findIndex((item) => item.period === data.item.period);
      if (idx !== -1) {
        this.repaymentSchedule[idx] = {
          ...this.repaymentSchedule[idx],
          [data.field.key]: val,
        };
      } else {
        this.repaymentSchedule.push({
          period: data.item.period,
          [data.field.key]: val,
        });
      }
    },
    validateRepaymentSchedule() {
      let valid = true;
      this.repaymentSchedule.forEach((element) => {
        const detailPeriod = this.payrollDetailInfor.repaymentSchedules.find(
          (item) => item.period === element.period,
        );
        if (element.penaltyAmount < detailPeriod.penaltyAmount) {
          valid = false;
        }
      });
      return valid;
    },
    handleConfirmUpdate() {
      if (!this.validateRepaymentSchedule()) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cập nhật thất bại ',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Số tiền nhập phải lớn số tiền đã thu',
          },
        });
        return;
      }
      updateRepaymentSchedule(this.id, this.repaymentSchedule)
        .then(() => {
          store.dispatch('app-payroll/fetchPayrollDetail', this.id);
          this.handleCancel();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thành công',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.handleCancel();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
