<template>
  <div>
    <payroll-detail-infor />
    <payroll-detail-salary-list
      v-if="$can(permissionCode.PAYROLL_CONTRACT.action, permissionCode.PAYROLL_CONTRACT.resource)"
    />
    <payroll-detail-payment />
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import permissionCode from '@/constants/permissionCode';
import PayrollDetailInfor from './PayrollDetailInfor.vue';
import PayrollDetailSalaryList from './PayrollDetailSalaryList.vue';
import PayrollDetailPayment from './PayrollDetailPayment.vue';
import payrollStoreModule from '../payrollStoreModule';

export default {
  components: {
    PayrollDetailInfor,
    PayrollDetailSalaryList,
    PayrollDetailPayment,
  },
  setup(props) {
    const PAYROLL_APP_STORE_MODULE_NAME = 'app-payroll';
    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
      }
    });
    store.dispatch('app-payroll/fetchPayrollDetail', router.currentRoute.params.id);
    return {
      permissionCode,
    };
  },
};
</script>
