<template>
  <b-card no-body class="mb-0 p-2">
    <h4 class="text-center mb-1">Thông tin khoản chi lương hộ</h4>
    <b-form>
      <b-row>
        <b-col cols="6">
          <b-form-group label-cols="4" label="Ngày tạo">
            <b-form-input
              id="createAt"
              :value="moment(payrollDetailInfor.createdAt).format('DD/MM/YYYY')"
              size="sm"
              placeholder="Ngày tạo"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Mã yêu cầu">
            <b-form-input
              id="code"
              :value="payrollDetailInfor.code"
              size="sm"
              placeholder="Mã yêu cầu"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Tổng số giao dịch">
            <b-form-input
              id="transactionAmount"
              :value="payrollDetailInfor.numOfTransfer"
              size="sm"
              placeholder="Số giao dịch"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Tổng số tiền chi lương">
            <b-form-input
              id="amount"
              :value="numberWithCommas(payrollDetailInfor.amount)"
              size="sm"
              placeholder="Tổng số tiền chi lương"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Ngày chi lương mong muốn">
            <b-form-input
              id="salaryDate"
              :value="moment(payrollDetailInfor.desiredDisbursedDate).format('DD/MM/YYYY')"
              size="sm"
              placeholder="Ngày chi lương mong muốn"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Ngày chi lương thực tế">
            <b-form-input
              id="realSalaryDate"
              :value="
                payrollDetailInfor.disbursedDate
                  ? moment(payrollDetailInfor.disbursedDate).format('DD/MM/YYYY')
                  : '_'
              "
              size="sm"
              placeholder="Ngày chi lương thực tế"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Xuất hoá đơn">
            <b-form-checkbox :checked="payrollDetailInfor.isGetInvoice" disabled />
          </b-form-group>
          <b-form-group label-cols="4" label="Trạng thái">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(payrollDetailInfor.status)}`"
              class="text-capitalize"
            >
              {{ resolveStatus(payrollDetailInfor.status) }}
            </b-badge>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-cols="4" label="Thời gian ứng">
            <b-form-input
              id="period"
              :value="payrollDetailInfor.term + ' ' + $t(payrollDetailInfor.duration)"
              size="sm"
              placeholder="Thời gian ứng"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Phí dịch vụ">
            <b-form-input
              id="serviceCharge"
              :value="numberWithCommas(payrollDetailInfor.serviceFee)"
              size="sm"
              placeholder="Phí dịch vụ"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Tổng số tiền thanh toán">
            <b-form-input
              id="paidAmount"
              :value="numberWithCommas(payrollDetailInfor.totalAmount)"
              size="sm"
              placeholder="Tổng số tiền thanh toán"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Đã thanh toán">
            <b-form-input
              id="paidAmount"
              :value="
                payrollDetailInfor.repaymentSchedules &&
                payrollDetailInfor.repaymentSchedules.length
                  ? numberWithCommas(paidAmount)
                  : '_'
              "
              size="sm"
              placeholder="Đã thanh toán"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Còn lại">
            <b-form-input
              id="paidAmount"
              :value="
                payrollDetailInfor.repaymentSchedules &&
                payrollDetailInfor.repaymentSchedules.length
                  ? numberWithCommas(payrollDetailInfor.totalAmount - paidAmount)
                  : '_'
              "
              size="sm"
              placeholder="Còn lại"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Ngày hẹn thanh toán">
            <b-form-input
              id="payDate"
              :value="
                (payrollDetailInfor.paymentAppointmentDate &&
                  moment(payrollDetailInfor.paymentAppointmentDate).format('DD/MM/YYYY')) ||
                '_'
              "
              size="sm"
              placeholder="Ngày đến hạn thanh toán"
              disabled
            />
          </b-form-group>
          <b-form-group label-cols="4" label="Ngày đến hạn thanh toán">
            <b-form-input
              id="payDate"
              :value="
                (payrollDetailInfor.nextPayDate &&
                  moment(payrollDetailInfor.nextPayDate).format('DD/MM/YYYY')) ||
                '_'
              "
              size="sm"
              placeholder="Ngày đến hạn thanh toán"
              disabled
            />
          </b-form-group>
          <div class="form-row">
            <p class="col-4 bv-no-focus-ring col-form-label col-form-label">Chứng từ</p>
            <p v-if="payrollDetailInfor.contractUrl" class="col text-info">
              <a :href="payrollDetailInfor.contractUrl" target="_blank"><ins>Hợp đồng ứng</ins></a>
            </p>
            <b-button
              v-if="
                !payrollDetailInfor.contractUrl &&
                payrollDetailInfor.status >= 5 &&
                $can(
                  permissionCode.PAYROLL_REGEN_CONTRACT.action,
                  permissionCode.PAYROLL_REGEN_CONTRACT.resource,
                )
              "
              @click="reGen"
              >Tải lại</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-form>
    <div class="text-center mt-1">
      <!-- <b-button
        v-if="(payrollDetailInfor.status == 0 || payrollDetailInfor.status == 1) && !isEdit"
        type="submit"
        variant="primary"
        @click="handleEdit()"
        >Sửa</b-button
      > -->
      <b-button
        v-if="
          (payrollDetailInfor.status === 0 ||
            payrollDetailInfor.status === 1 ||
            payrollDetailInfor.status === 11 ||
            payrollDetailInfor.status === 4 ||
            payrollDetailInfor.status === 5) &&
          $can(permissionCode.PAYROLL_CANCEL.action, permissionCode.PAYROLL_CANCEL.resource)
        "
        v-b-modal.modal-1
        type="submit"
        variant="outline-primary"
        class="mr-1"
        >Huỷ đăng ký</b-button
      >
      <b-button
        v-if="payrollDetailInfor.status == 6 || payrollDetailInfor.status == 8"
        variant="primary"
        style="margin: 5px"
        @click="handlePayroll()"
        >Thanh toán</b-button
      >
      <b-modal
        id="modal-1"
        title="Xác nhận"
        cancel-title="Huỷ"
        ok-title="Xác nhận"
        @ok="handleCancelPayroll"
      >
        <p class="my-0">Bạn có chắc chắn muốn HUỶ đăng ký chi lương không?</p>
      </b-modal>
      <b-modal
        ref="modal-repayment"
        title="Ghi nhận thanh toán"
        @ok="confirmPayment()"
        @hidden="resetModal()"
      >
        <b-row class="align-items-end">
          <b-col cols="12" md="12" class="mb-1">
            <b-form-group class="mr-1" style="width: 100% !important">
              <label>{{ $t('Implementation date') }} <span class="text-danger">*</span></label>
              <b-form-datepicker
                v-model="createAt"
                placeholder=""
                local="VI"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                style="z-index: 10000"
                locale="en-GB"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <b-form-group style="width: 100% !important" class="mr-1">
              <label>{{ $t('Next payment date') }}</label>
              <b-form-datepicker
                v-model="paymentAppointmentDate"
                :disabled="remainAmount === 0"
                :min="createAt"
                placeholder=""
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                style="z-index: 1000"
                locale="en-GB"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <label>{{ $t('Transaction code') }}<span class="text-danger">*</span></label>
            <b-form-input v-model="statementCode" class="d-inline-block mr-1" />
          </b-col>

          <b-col cols="12" md="12" class="mb-1">
            <label for="paymentAmount"
              >{{ $t('Payment amount') }} <span class="text-danger">*</span></label
            >

            <b-form-input id="paidAmount" v-model="formattedValue" class="d-inline-block mr-1" />
          </b-col>
        </b-row>

        <div class="btn-unc">
          <label
            for="uploadUNC"
            role="button"
            class="bg-primary rounded text-white"
            style="padding: 8px; font-size: 12px"
            >{{ $t('Upload UNC') }}</label
          >
          <input
            id="uploadUNC"
            ref="file"
            type="file"
            multiple
            class="w-px h-px opacity-0 hidden absolute"
            accept=".png,.jpg"
            @change="onChange()"
          />
          <ul
            v-for="(file, idx) in listFileUNC"
            :key="idx"
            style="list-style-type: none; padding: 0"
          >
            <li class="item-file-unc">{{ file.name }}</li>
          </ul>
        </div>
        <template #modal-footer="{ ok, cancel }">
          <b-button variant="success" @click="ok()"> Xác nhận </b-button>
          <b-button variant="danger" @click="cancel()"> Hủy </b-button>
        </template>
      </b-modal>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BFormDatepicker,
  BFormSelect,
  BModal,
  BBadge,
} from 'bootstrap-vue';
import store from '@/store';
import router from '@/router';
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { numberWithCommas, numberWithoutCommas } from '@core/utils/utils';
import { cancelPayroll, reGenContract, requestPayment } from '@/apis/apiPayroll';
import permissionCode from '@/constants/permissionCode';
import constRouter from '@/constants/constRouter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import usePayrollList from '../payroll-list/usePayrollList';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    BModal,
    BBadge,
  },
  setup() {
    const { resolveUserStatusVariant, resolveStatus, refetchData } = usePayrollList();
    return {
      resolveUserStatusVariant,
      resolveStatus,
      permissionCode,
      refetchData,
    };
  },
  data() {
    return {
      listFileUNC: [],
      statements: [],
      remainAmount: '',
      busy: false,
      paymentAppointmentDate: null,
      amount: '',
      statementCode: '',
      numberWithCommas,
      moment,
      constRouter,
      term: '',
      isEdit: '',
      salaryDay: new Date(new Date().setDate(new Date().getDate() + 2)),
      minDay: new Date(new Date().setDate(new Date().getDate() + 2)),
      createAt: new Date(),
    };
  },

  computed: {
    ...mapGetters({
      payrollDetailInfor: 'app-payroll/getPayrollDetailInfor',
    }),
    paidAmount() {
      return this.payrollDetailInfor.repaymentSchedules?.reduce(
        (total, val) => total + val.paidAmount,
        0,
      );
    },
    formattedValue: {
      get() {
        return numberWithCommas(this.amount);
      },
      set(newValue) {
        this.amount = numberWithoutCommas(newValue);
      },
    },
    remainAmountCurrent() {
      return this.payrollDetailInfor.currentRequiredAmount;
    },
  },
  watch: {
    remainAmountCurrent() {
      this.amount = this.remainAmountCurrent;
    },
  },

  mounted() {
    if (!localStorage.getItem('firstPayment')) {
      setTimeout(() => {
        this.$intro().start();
      }, 1000);
      localStorage.setItem('firstPayment', false);
    }
  },

  methods: {
    resetModal() {
      this.statementCode = '';
    },
    handlePayroll() {
      this.$refs['modal-repayment'].show();
    },
    onChange() {
      this.listFileUNC.push(...this.$refs.file.files);
    },
    confirmPayment() {
      const form = new FormData();
      this.listFileUNC.forEach((element) => {
        form.append('statements', element);
      });
      if (this.paymentAppointmentDate) {
        form.append('paymentAppointmentDate', this.paymentAppointmentDate);
      }
      form.append('statementCode', this.statementCode);
      form.append('performedAt', moment(this.createAt).format('YYYY-MM-DD'));
      form.append('amount', parseInt(this.amount, 10));
      console.log(this.amount);
      requestPayment(form, this.$route.params.id)
        .then(() => {
          this.busy = false;
          setTimeout(() => {
            this.refetchData();
          }, 50);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ghi nhận thanh toán thành côngÏ',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ghi nhận thanh toán thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          });
        });
    },
    handleEdit() {
      this.isEdit = true;
    },
    handleCancelPayroll() {
      cancelPayroll(this.payrollDetailInfor.id)
        .then(() => {
          store.dispatch('app-payroll/fetchPayrollDetail', router.currentRoute.params.id);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Huỷ thành công ',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Huỷ thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    reGen() {
      reGenContract(this.payrollDetailInfor.id)
        .then(() => {
          store.dispatch('app-payroll/fetchPayrollDetail', router.currentRoute.params.id);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Huỷ thất bại ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
  },
};
</script>
